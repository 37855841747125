
import { configureStore } from '@reduxjs/toolkit';
import { appManagementSlice, employeeSlice } from './EmployeeRedux';


const store = configureStore({
    reducer : { employee: employeeSlice.reducer,appManagement: appManagementSlice.reducer}
});


export const employeeActions = employeeSlice.actions;
export const appManagementActions = appManagementSlice.actions;

export default store;
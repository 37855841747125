import {
  EmailAuthProvider,
  reauthenticateWithCredential,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  updatePassword
} from "firebase/auth";
import React, { useContext, useEffect, useState } from "react";
import OneSignal from 'react-onesignal';
import APIUrls from "../BaseUrl";
import { auth } from "../Firebase";
import NetworkIssuePage from "../helpers/NetworkIssuePage";

const AuthContext = React.createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthContextProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState();
  const [userRole, setUserRole] = useState();
  const [userInfo, setUserInfo] = useState();
  const [loading, setLoading] = useState(true);

  const [isOnline, setIsOnline] = useState(window.navigator.onLine);



  // Updating online status
  useEffect(() => {
    const handleNetworkStatusChange = () => {
      setIsOnline(window.navigator.onLine);
    };

    window.addEventListener("online", handleNetworkStatusChange);
    window.addEventListener("offline", handleNetworkStatusChange);

    return;

    window.location.reload();

    window.removeEventListener("online", handleNetworkStatusChange);
    window.removeEventListener("offline", handleNetworkStatusChange);
  }, []);

  function login(email, password) {
    return signInWithEmailAndPassword(auth, email, password);
  }

  function logout() {
    return signOut(auth);
  }

  function resetPassword(email) {
    return sendPasswordResetEmail(auth, email);
  }

  function reauthenticate(creds) {
    const credentials = EmailAuthProvider.credential(
      currentUser?.email,
      creds.password
    );
    return reauthenticateWithCredential(currentUser, credentials);
  }

  function updateUser(uid, role) {
    return updateUser(uid, role);
  }

  function updateEmail(email) {
    return currentUser.updateEmail(email);
  }

  function changePassword(data) {
    return updatePassword(currentUser, data.newPassword);
  }

  function getAccessToken() {
    return currentUser?.getIdToken(true);
  }
  function getCurrentUser() {
    return currentUser.getUser();
  }
  function saveUserRole(value) {
    setUserRole(value);
  }

  const getUserRoleFunc = async (user) => {
    try {
      const result = await fetch(APIUrls.getUserRole, {
        method: "GET",
        headers: {
          Authorization: `Bearer ${user.accessToken}`,
        },
      });
      const resultJson = await result.json();

      if (!result.ok) {
        throw new Error("Failed to login");
      }

      setUserRole(resultJson?.response?.role);
      setUserInfo(resultJson?.response);
      await OneSignal.login(resultJson?.response?._id);
    } catch (error) {}
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      setCurrentUser(user);
      await getUserRoleFunc(user);

      setLoading(false);
    });

    return unsubscribe;
  }, []);

  const value = {
    currentUser,
    login,
    logout,
    resetPassword,
    updateEmail,
    changePassword,
    getAccessToken,
    updateUser,
    getCurrentUser,
    userRole,
    saveUserRole,
    setUserInfo,
    userInfo,
    reauthenticate,
  };

  // Based on the online status Network issue page and respective component will be shown

  if (!isOnline) {
    return <NetworkIssuePage />;
  } else {
    return (
      <AuthContext.Provider value={value}>
        {!loading && children}
      </AuthContext.Provider>
    );
  }
};

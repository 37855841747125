import React from "react";
import ReactDOM from "react-dom/client";
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import "./index.css";
import App from "./App";
import "flatpickr/dist/flatpickr.min.css";
import reportWebVitals from "./reportWebVitals";
import { AuthContextProvider } from "./stores/AuthContext";
import { Provider } from "react-redux";
import store from "./stores/IndexRedux";


const root = ReactDOM.createRoot(document.getElementById("root"));
export const queryClient = new QueryClient();
root.render(
  <AuthContextProvider>
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <React.StrictMode>
          <App />
        </React.StrictMode>
      </QueryClientProvider>
    </Provider>
  </AuthContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

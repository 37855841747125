import React, { Suspense } from "react";
import { createBrowserRouter, Navigate } from "react-router-dom";
import EjoyshopLoader from "../components/loader/EjoyshopLoader";
import { useAuth } from "../stores/AuthContext";
const VendorLogin = React.lazy(() => import("../pages/login/VendorLogin"));
const VendorForgotPassord = React.lazy(() =>
  import("../pages/login/VendorForgotPassord")
);
const VendorLoginCard = React.lazy(() =>
  import("../pages/login/VendorLoginCard")
);
const Vendor = React.lazy(() => import("../pages/vendor/Vendor"));
const VendorPanelLayout = React.lazy(() =>
  import("../layouts/VendorPanelLayout")
);
const VendorDashboard = React.lazy(() =>
  import("../components/vendorPanel/VendorDashboard")
);
const VendorMyMeetings = React.lazy(() =>
  import("../components/vendorPanel/VendorMyMeetings")
);
const VendorOrders = React.lazy(() =>
  import("../components/vendorPanel/VendorOrders")
);
const VendorReports = React.lazy(() =>
  import("../components/vendorPanel/VendorReports")
);
const VendorMyStores = React.lazy(() =>
  import("../components/vendorPanel/VendorMyStores")
);
const VendorSettings = React.lazy(() =>
  import("../components/vendorPanel/VendorSettings")
);
const VendorChat = React.lazy(() =>
  import("../components/vendorPanel/VendorChat")
);

// Not yet implemented just hide
const VendorPremiumFeatures = React.lazy(() =>
  import("../components/vendorPanel/VendorPremiumFeatures")
);

const OrderLists = React.lazy(() =>
  import("../components/orderModules/OrderLists")
);
const OrderCreate = React.lazy(() =>
  import("../components/orderModules/OrderCreate")
);
const OrderEdit = React.lazy(() =>
  import("../components/orderModules/OrderEdit")
);
const InvoiceDocumentCard = React.lazy(() =>
  import("../helpers/InvoiceDocument")
);
const SalesReports = React.lazy(() =>
  import("../components/reportsModules/SalesReports")
);
const OrdersReports = React.lazy(() =>
  import("../components/reportsModules/OrdersReports")
);
const EarningsReports = React.lazy(() =>
  import("../components/reportsModules/EarningsReports")
);
const VendorShipment = React.lazy(() =>
  import("../components/vendorPanel/VendorShipment")
);
const ShipmentList = React.lazy(() =>
  import("../components/shipmentModules/ShipmentList")
);
const CreateShipment = React.lazy(() =>
  import("../components/shipmentModules/CreateShipment")
);
const ProfileSettings = React.lazy(() =>
  import("../components/settingsModules/ProfileSettings")
);
const ShopDetails = React.lazy(() =>
  import("../components/settingsModules/ShopDetails")
);
const Settings = React.lazy(() =>
  import("../components/settingsModules/Settings")
);
const VendorPanelOffers = React.lazy(() =>
  import("../components/vendorPanel/VendorPanelOffers")
);
const OffersList = React.lazy(() =>
  import("../components/offerModules/OffersList")
);
const Plans = React.lazy(() => import("../components/offerModules/Plans"));
const AddOffer = React.lazy(() =>
  import("../components/offerModules/AddOffer")
);
const ShipmentTrack = React.lazy(() =>
  import("../components/shipmentModules/ShipmentTrack")
);
const Address = React.lazy(() => import("../components/vendorPanel/Address"));

const VendorPanelSupport = React.lazy(() =>
  import("../components/vendorPanel/VendorPanelSupport")
);
const ShopCover = React.lazy(() =>
  import("../components/settingsModules/ShopCover")
);
const Offers = React.lazy(() => import("../components/offerModules/Offers"));
const EditOffer = React.lazy(() =>
  import("../components/offerModules/EditOffer")
);
const OfferParent = React.lazy(() =>
  import("../components/offerModules/OfferParent")
);
const AddSupport = React.lazy(() =>
  import("../components/supportModules/AddSupport")
);
const EditSupport = React.lazy(() =>
  import("../components/supportModules/EditSupport")
);
const Credits = React.lazy(() => import("../components/offerModules/Credits"));

const PageNotFound = React.lazy(() => import("../helpers/PageNotFound"));

const PrivateRoute = ({ component: Component }) => {
  const { currentUser, userRole } = useAuth();
  return (
    <>
      {currentUser && userRole?.vendor ? (
        <Suspense fallback={<EjoyshopLoader />}>
          <Component />
        </Suspense>
      ) : (
        <Navigate to='/vendorLogin' replace />
      )}
    </>
  );
};

export const router = createBrowserRouter([
  {
    path: "/vendorLogin",
    element: (
      <Suspense fallback={<EjoyshopLoader />}>
        <VendorLogin />
      </Suspense>
    ),
    children: [
      {
        path: "/vendorLogin",
        element: (
          <Suspense fallback={<EjoyshopLoader />}>
            <VendorLoginCard />
          </Suspense>
        ),
      },
      {
        path: "forgotPassword",
        element: (
          <Suspense fallback={<EjoyshopLoader />}>
            <VendorForgotPassord />
          </Suspense>
        ),
      },
    ],
  },
  {
    path: "/",
    element: (
      <Suspense fallback={<EjoyshopLoader />}>
        <PrivateRoute component={VendorPanelLayout} />
      </Suspense>
    ),
    children: [
      {
        path: "/",
        element: (
          <Suspense fallback={<EjoyshopLoader />}>
            <PrivateRoute component={VendorDashboard} />
          </Suspense>
        ),
      },
      {
        path: "myMeetings",
        element: (
          <Suspense fallback={<EjoyshopLoader />}>
            <PrivateRoute component={VendorMyMeetings} />
          </Suspense>
        ),
      },
      {
        path: "orders",
        element: (
          <Suspense fallback={<EjoyshopLoader />}>
            {" "}
            <PrivateRoute component={VendorOrders} />
          </Suspense>
        ),
        children: [
          {
            path: "",
            element: (
              <Suspense fallback={<EjoyshopLoader />}>
                <PrivateRoute component={OrderLists} />
              </Suspense>
            ),
          },
          {
            path: "create",
            element: (
              <Suspense fallback={<EjoyshopLoader />}>
                <PrivateRoute component={OrderCreate} />
              </Suspense>
            ),
          },
          {
            path: "edit/:id",
            element: (
              <Suspense fallback={<EjoyshopLoader />}>
                <PrivateRoute component={OrderEdit} />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "reports",
        element: (
          <Suspense fallback={<EjoyshopLoader />}>
            {" "}
            <PrivateRoute component={VendorReports} />
          </Suspense>
        ),
        children: [
          {
            path: "",
            element: (
              <Suspense fallback={<EjoyshopLoader />}>
                {" "}
                <PrivateRoute component={SalesReports} />
              </Suspense>
            ),
          },
          {
            path: "earnings-reports",
            element: (
              <Suspense fallback={<EjoyshopLoader />}>
                {" "}
                <PrivateRoute component={EarningsReports} />
              </Suspense>
            ),
          },
          {
            path: "orders-reports",
            element: (
              <Suspense fallback={<EjoyshopLoader />}>
                {" "}
                <PrivateRoute component={OrdersReports} />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "myStore",
        element: (
          <Suspense fallback={<EjoyshopLoader />}>
            {" "}
            <PrivateRoute component={VendorMyStores} />
          </Suspense>
        ),
      },

      {
        path: "offers",
        element: (
          <Suspense fallback={<EjoyshopLoader />}>
            <PrivateRoute component={VendorPanelOffers} />
          </Suspense>
        ),
        children: [
          {
            path: "",
            element: (
              <Suspense fallback={<EjoyshopLoader />}>
                <PrivateRoute component={OfferParent} />
              </Suspense>
            ),
            children: [
              {
                path: "",
                element: (
                  <Suspense fallback={<EjoyshopLoader />}>
                    <PrivateRoute component={Plans} />
                  </Suspense>
                ),
              },

              {
                path: "offer-list",
                element: (
                  <Suspense fallback={<EjoyshopLoader />}>
                    <PrivateRoute component={OffersList} />
                  </Suspense>
                ),
              },
              {
                path: "credits",
                element: (
                  <Suspense fallback={<EjoyshopLoader />}>
                    <PrivateRoute component={Credits} />
                  </Suspense>
                ),
              },
            ],
          },

          {
            path: "add-offer",
            element: (
              <Suspense fallback={<EjoyshopLoader />}>
                <PrivateRoute component={AddOffer} />
              </Suspense>
            ),
          },
          {
            path: "offer-requests/:id",
            element: (
              <Suspense fallback={<EjoyshopLoader />}>
                <PrivateRoute component={EditOffer} />
              </Suspense>
            ),
          },
        ],
      },

      {
        path: "shipment",
        element: (
          <Suspense fallback={<EjoyshopLoader />}>
            {" "}
            <PrivateRoute component={VendorShipment} />
          </Suspense>
        ),

        children: [
          {
            path: "",
            element: (
              <Suspense fallback={<EjoyshopLoader />}>
                {" "}
                <PrivateRoute component={ShipmentList} />
              </Suspense>
            ),
          },
          {
            path: "create-shipment",
            element: (
              <Suspense fallback={<EjoyshopLoader />}>
                <PrivateRoute component={CreateShipment} />
              </Suspense>
            ),
          },
          {
            path: "shipment-track/:id",
            element: (
              <Suspense fallback={<EjoyshopLoader />}>
                <PrivateRoute component={ShipmentTrack} />
              </Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <Suspense fallback={<EjoyshopLoader />}>
                <PrivateRoute component={CreateShipment} />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "address",
        element: (
          <Suspense fallback={<EjoyshopLoader />}>
            <PrivateRoute component={Address} />
          </Suspense>
        ),
      },
      {
        path: "settings",
        element: (
          <Suspense fallback={<EjoyshopLoader />}>
            <PrivateRoute component={VendorSettings} />
          </Suspense>
        ),

        children: [
          {
            path: "",
            element: (
              <Suspense fallback={<EjoyshopLoader />}>
                <PrivateRoute component={ProfileSettings} />
              </Suspense>
            ),
          },
          {
            path: "shop-details",
            element: (
              <Suspense fallback={<EjoyshopLoader />}>
                <PrivateRoute component={ShopDetails} />
              </Suspense>
            ),
          },
          {
            path: "shop-cover",
            element: (
              <Suspense fallback={<EjoyshopLoader />}>
                <PrivateRoute component={ShopCover} />
              </Suspense>
            ),
          },
          {
            path: "additional-settings",
            element: (
              <Suspense fallback={<EjoyshopLoader />}>
                <PrivateRoute component={Settings} />
              </Suspense>
            ),
          },
        ],
      },
      {
        path: "chat",
        element: (
          <Suspense fallback={<EjoyshopLoader />}>
            <PrivateRoute component={VendorChat} />
          </Suspense>
        ),
      },
      {
        path: "support",
        element: (
          <Suspense fallback={<EjoyshopLoader />}>
            <PrivateRoute component={VendorPanelSupport} />
          </Suspense>
        ),
        children: [
          {
            path: "",
            element: (
              <Suspense fallback={<EjoyshopLoader />}>
                <PrivateRoute component={AddSupport} />
              </Suspense>
            ),
          },
          {
            path: ":id",
            element: (
              <Suspense fallback={<EjoyshopLoader />}>
                <PrivateRoute component={EditSupport} />
              </Suspense>
            ),
          },
        ],
      },
      // {
      //   path: "support-chat",
      //   element: (
      //     <Suspense fallback={<EjoyshopLoader />}>
      //       <PrivateRoute component={VendorPanelSupportChat} />
      //     </Suspense>
      //   ),
      // },
      // {
      //   path: "premiumFeatures",
      //   element: (
      //     <Suspense fallback={<EjoyshopLoader />}>
      //       <PrivateRoute component={VendorPremiumFeatures} />
      //     </Suspense>
      //   ),
      // },
    ],
  },
  {
    path: "/invoice",
    element: <InvoiceDocumentCard />,
  },

  {
    path: "*",
    element: <PageNotFound />, //Incase of  invalid path is given this component will shown
  },
]);

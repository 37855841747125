const baseUrl = process.env.REACT_APP_BASEURL;

//const baseUrl = "http://localhost:4001/api/v1";
//const baseUrl = "https://k3qw61dm-4001.inc1.devtunnels.ms/api/v1";

export const createVendorUrl = `${baseUrl}/vendor/createVendor`;
export const verifyGSTIN = `${baseUrl}/verificationSuit/verifyGSTIN`;
export const verifyPAN = `${baseUrl}/verificationSuit/verifyPAN`;
export const generateOTPUrl = `${baseUrl}/verificationSuit/generateAadhaarOTP`;
export const verifyAadhaarOTPUrl = `${baseUrl}/verificationSuit/submitAadhaarOTP`;
export const verifyBankUrl = `${baseUrl}/verificationSuit/bankVerification`;
const APIUrls = {
  getVendors: `${baseUrl}/vendor/getVendors`,
  forgotPassword: `${baseUrl}/vendor/forgot-password`,
  getCitiesAndStates: `${baseUrl}/vendor/getCitiesAndStates`,
  getUserRole: `${baseUrl}/userRoles`,
  getEmplyeeDetails: `${baseUrl}/emp/getEmployeeDetails`,
  getVendorById: `${baseUrl}/vendor/getVendorById`,
  updateVendor: `${baseUrl}/vendor/updateVendor`,
  getAllEmployees: `${baseUrl}/emp/getAllEmployees`,
  getEmployeeById: `${baseUrl}/emp/getEmployeeById`,
  updateEmployee: `${baseUrl}/emp/updateEmp`,
  deleteEmployee: `${baseUrl}/emp/deleteEmployee`,
  getAdminDetails: `${baseUrl}/admin/getAdminDetails`,
  check_GSTIN_Exists: `${baseUrl}/vendor/check_GSTIN_Exists`,
  checkBusinessIEC_Exists: `${baseUrl}/vendor/checkBusinessIEC_Exists`,
  checkBusinessPAN_Exists: `${baseUrl}/vendor/checkBusinessPAN_Exists`,
  checkEmailExists: `${baseUrl}/vendor/checkEmailExists`,
  checkMobileNumberExists: `${baseUrl}/vendor/checkMobileNumberExists`,
  fetchMeetings: (searchKey, fromDate, toDate, status, download, page) =>
    `${baseUrl}/vendor/meetings?searchKey=${searchKey}&fromDate=${fromDate}&toDate=${toDate}&status=${status}&download=${download}&page=${page}&limit=10`,

  fetchMeetingStats: `${baseUrl}/vendor/meetings/stats`,
  joinMeet: `${baseUrl}/vendor/meet/join`,
  searchCustomer: (searchKey) =>
    `${baseUrl}/vendor/customers/search?searchKey=${searchKey}`,
  updateDailyTimings: `${baseUrl}/vendor/daily-timings`,
  stores: {
    fetchStoreDetails: `${baseUrl}/vendor/store`,
    createStore: `${baseUrl}/vendor/store`,
    fetchCategoryDetails: `${baseUrl}/vendor/categories-names`,
    fetchLanguages: `${baseUrl}/vendor/languages`,
    fetchOccasions: `${baseUrl}/vendor/occasions`,
  },
  support: {
    fetchSupportList: (searchKey, status, fromDate, toDate, download, page) =>
      `${baseUrl}/vendor/supports?searchKey=${searchKey}&status=${status}&fromDate=${fromDate}&toDate=${toDate}&download=${download}&page=${page}`,
    fetchSupportById: (id) => `${baseUrl}/vendor/supports/${id}`,
    updateSupport: (id) => `${baseUrl}/vendor/supports/${id}`,
    addSupport: `${baseUrl}/vendor/supports`,
  },

  fetchCredits: (searchKey, status, fromDate, toDate, page) =>
    `${baseUrl}/vendor/credits?searchKey=${searchKey}&status=${status}&fromDate=${fromDate}&toDate=${toDate}&page=${page}`,
};

const vendorBaseUrl = `${baseUrl}/vendor`;

export const VendorAuthorURL = {
  order: {
    createOrder: `${vendorBaseUrl}/orders/create`,
    updateOrder: (id) => `${vendorBaseUrl}/orders/${id}/update`,
    fetchOrders: (searchKey, orderStatus, fromDate, toDate, page, download) =>
      `${vendorBaseUrl}/orders?searchKey=${encodeURIComponent(
        searchKey || ""
      )}&status=${encodeURIComponent(
        orderStatus || ""
      )}&fromDate=${encodeURIComponent(
        fromDate || ""
      )}&toDate=${encodeURIComponent(
        toDate || ""
      )}&page=${page}&limit=10&download=${download}`,
    fetchOrderStats: `${vendorBaseUrl}/orders/stats`,
    fetchOrderById: (id) => `${vendorBaseUrl}/orders/${id}`,
  },
  meetings: {},
  address: {
    fetchAddresses: `${vendorBaseUrl}/address`,

    fetchAddressById: (id) => `${vendorBaseUrl}/address/${id}`,

    createAddress: (isPickupAddress) =>
      `${vendorBaseUrl}/address?isPickupAddress=${isPickupAddress}`,
    deleteAddress: (id) => `${vendorBaseUrl}/address/${id}`,
    updateDefaultAddress: (id) => `${vendorBaseUrl}/address/${id}/mark-default`,
    fetchActiveAddresses: (searchKey) =>
      `${vendorBaseUrl}/address/active?searchKey=${searchKey}`,
  },

  shipment: {
    fetchShipmentDetails: (
      searchKey,
      fromDate,
      toDate,
      status,
      download,
      page
    ) =>
      `${vendorBaseUrl}/shipments?searchKey=${searchKey}&fromDate=${fromDate}&toDate=${toDate}&status=${status}&download=${download}&page=${page}&limit=10`,

    fetchShipmentDetailsBySpecificId: (id) =>
      `${vendorBaseUrl}/shipments/${id}`,
    trackingShipmentDetails: (id) => `${vendorBaseUrl}/shipments/${id}/track`,
  },
  offer: {
    fetchOfferPlans: `${vendorBaseUrl}/offer-plans`,
    createNewOffer: `${vendorBaseUrl}/offers`,
    fetchOffers: (searchKey, status, download, page) =>
      `${vendorBaseUrl}/offers?searchKey=${searchKey}&approvalStatus=${status}&download=${download}&page=${page}`,
    fetchSpecificOffer: (id) => `${vendorBaseUrl}/offers/${id}`,
    deleteSpecificOffer: (id) => `${vendorBaseUrl}/offers/${id}`,
    editSpecificOffer: (id) => `${vendorBaseUrl}/offers/${id}`,
  },

  settings: {
    fetchVendorDetails: `${vendorBaseUrl}/vendors`,
    updateVendorDetails: `${vendorBaseUrl}/update-vendor`,
    updateProfile: `${vendorBaseUrl}/update-profile/`,
    updateShopDetails: `${vendorBaseUrl}/update-vendor/`,
  },
  offer: {
    fetchOfferPlans: `${vendorBaseUrl}/offer-plans`,
    fetchOffers: (searchKey, approvalStatus, status, download, page) =>
      `${vendorBaseUrl}/offers?searchKey=${searchKey}&approvalStatus=${approvalStatus}&status=${status}&download=${download}&page=${page}`,
    fetchOfferById: (id) => `${vendorBaseUrl}/offers/${id}`,
    addOffers: `${vendorBaseUrl}/offers`,
    updateOffers: (id) => `${vendorBaseUrl}/offers/${id}`,
    deleteOffers: (id) => `${vendorBaseUrl}/offers/${id}`,
  },
  reports: {
    fetchSalesReports: (fromMonth, toMonth, vendorId) =>
      `${vendorBaseUrl}/report/sales?fromMonth=${fromMonth}&toMonth=${toMonth}&vendorId=${vendorId}`,

    fetchEarningsReports: (fromMonth, toMonth) =>
      `${vendorBaseUrl}/report/earnings?fromMonth=${fromMonth}&toMonth=${toMonth}`,
    fetchOrderReports: (fromMonth, toMonth) =>
      `${vendorBaseUrl}/report/orders?fromMonth=${fromMonth}&toMonth=${toMonth}`,
    fetchEarningOrderReports: (fromDate, toDate, searchKey, page) =>
      `${vendorBaseUrl}/report/earnings/records?fromDate=${fromDate}&toDate=${toDate}&searchKey=${searchKey}&page=${page}`,
    fetchOrderStats: `${vendorBaseUrl}/orders/stats`,
  },
  payment: {
    createPaymentOrder: `${vendorBaseUrl}/credits/create-order`,
  },
};

export const paymentUrl = {
  verifyPayment: `${baseUrl}/pay/credits/verify`,
  paymentSuccess: `${baseUrl}/pay/credits/success`,
};

export default APIUrls;

import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  employeeDetails: null,
  formSaveLoaderBtn: false,
  userName: null,
  userId: null,
  searchFlow: "",
};

export const employeeSlice = createSlice({
  name: "employee",
  initialState,
  reducers: {
    updateEmployeeDetails(state, action) {
      state.employeeDetails = action.payload.employeeDetails;
    },
    updateUserName(state, action) {
      state.userName = action.payload.userName;
    },
    updateUserId(state, action) {
      state.userId = action.payload.userId;
    },
    updateSearchFlow(state, action) {
      state.searchFlow = action.payload;
    },
  },
});

const appManagmentState = {
  chatUnreadMessageCounts:null,
  chatGroups:[],
  notifications:{
    personalized:[],
    vendorGeneral:[],
    general:[],
    unreadPersonlizedCounts:0,
    unreadVendorGeneralCounts:0,
    unreadGeneralCounts:0,
    newNotification:false
  }
};

export const appManagementSlice = createSlice({
  name: "appManagement",
  initialState:appManagmentState,
  reducers: {
    updateAppManagments(state, action) {
      return {
        ...state,
        chatUnreadMessageCounts: action.payload.chatUnreadMessageCounts,
        chatGroups: action.payload.chatGroups,
      };
    },
    updateNotifications(state,action){
     
        state.notifications = {
          ...state.notifications,
          ...action.payload,
        };
      
    }
     
  },
});

import { get, onValue, ref, update } from "firebase/database";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import OneSignal from "react-onesignal";
import { useDispatch, useSelector } from "react-redux";
import { RouterProvider } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import EjoyshopLoader from "./components/loader/EjoyshopLoader";
import { database } from "./Firebase";
import {
  PlayMeetingReminderSound,
  PlayNotificationSound,
  updateNotificationStatus,
} from "./helpers/HelperFunc";
import { settingUnseenGroupsChat } from "./realtimeDataBase/handlers/ChatFunctions";
import { router } from "./routes/Route";
import { useAuth } from "./stores/AuthContext";
import { appManagementActions } from "./stores/IndexRedux";
function App() {
  const [loading, setLoading] = useState(true);
  const [loadingC, setLoadingC] = useState(false);
  const { userInfo } = useAuth();
  const { personalized, vendorGeneral, general } = useSelector(
    (state) => state.appManagement.notifications
  );

  const dispatch = useDispatch();

  useEffect(() => {
    const images = [
      "./assets/cryptocurrency-record.png",
      "./assets/newVdo.mp4",
      "./assets/Ellipse 825.png",
      "./assets/Ellipse 826.png",
      "./assets/final 5.jpg",
      "./assets/Frame 37352.png",
      "./assets/Frame 3734.png",
      "./assets/loginAvatar.svg",
      "./assets/loginEllipse1.svg",
      "./assets/loginEllipse2.svg",
      "./assets/loginEllipse3.svg",
      "./assets/PATTERNS.png",
      "./assets/premiumFeatures.svg",
      "./assets/transaction 1 (1).png",
      "./assets/transaction 1 (2).png",
      "./assets/transaction 1.png",
      "./assets/Vector (1).png",
      "./assets/Vector (2).png",
      "./assets/Vector (3).png",
      "./assets/Vector 244.png",
      "./assets/Vector 245.png",
      "./assets/Vector.png",
      "./assets/VectorAvatar.svg",
    ];

    cacheImages(images);
  }, []);

  const cacheImages = async (srcArr) => {
    const promises = await srcArr.map((src) => {
      return new Promise((resolve, reject) => {
        const img = new Image();

        img.src = src;
        img.onload = resolve();
        img.onerror = reject();
      });
    });
    await Promise.all(promises);
    setLoading(false);
  };

  useEffect(() => {
    const groupMembersRef = ref(database, "groups");

    const unsubscribe = onValue(groupMembersRef, async (snapshot) => {
      // The callback function will be invoked whenever the data changes
      const groupsData = snapshot.val();

      await settingUnseenGroupsChat(groupsData, userInfo?.userId);
    });

    return () => unsubscribe();
  }, [userInfo?.userId]);

  useEffect(() => {
    const notificationsPath = `notifications/vendors/${userInfo?.userId?.toString()}`;
    const notificationsRef = ref(database, notificationsPath);
    const unsubscribe = onValue(notificationsRef, (snapshot) => {
      dispatch(
        appManagementActions.updateNotifications({
          personalized: snapshot.val() || [],
        })
      );
    });

    const notificationsVendorGeneralPath = `notifications/vendors/general`;
    const notificationsVendorGeneralRef = ref(
      database,
      notificationsVendorGeneralPath
    );
    const unsubscribeVendorGeneral = onValue(
      notificationsVendorGeneralRef,
      (snapshot) => {
        dispatch(
          appManagementActions.updateNotifications({
            vendorGeneral: snapshot.val() || [],
          })
        );
      }
    );
    const notificationsGeneralPath = `notifications/general`;
    const notificationsGeneralRef = ref(database, notificationsGeneralPath);
    const unsubscribeGeneral = onValue(notificationsGeneralRef, (snapshot) => {
      dispatch(
        appManagementActions.updateNotifications({
          general: snapshot.val() || [],
        })
      );
    });

    return () => {
      unsubscribe();
      unsubscribeVendorGeneral();
      unsubscribeGeneral();
    };
  }, [userInfo?.userId, dispatch]);


  // Fetching personalised notifications
  const calculateUnreadPersonalized = useCallback(
    (notifications) => {
      let unread = 0;
      const notificationIds = Object.keys(notifications || {});

      notificationIds
        .slice(0)
        .reverse()
        .forEach((notificationId) => {
          const notification = { ...notifications[notificationId] };
          notification.id = notificationId;

          if (notification.status === "sent") {
            if (notification.type === "meeting_reminder") {
              PlayMeetingReminderSound();
            } else {
              PlayNotificationSound();
            }
            updateNotificationStatus(
              `notifications/vendors/${userInfo?.userId?.toString()}/${
                notification?.id
              }/`,
              "unread"
            );
            update(ref(database, `users/${userInfo?.userId}/`), {
              newNotification: true,
            });
          }

          if (notification.status === "unread") {
            unread += 1;
          }
        });

      dispatch(
        appManagementActions.updateNotifications({
          unreadPersonalizedCounts: unread,
        })
      );
      return unread;
    },
    [dispatch, userInfo?.userId]
  );

  // Fetching vendor updates notifications
  const calculateUnreadVendorGeneral = useCallback(
    async (notifications) => {
      let unread = 0;
      const notificationIds = Object.keys(notifications || {});

      await Promise.all(
        notificationIds
          .slice(0)
          .reverse()
          .map(async (notificationId) => {
            const notification = { ...notifications[notificationId] };
            notification.id = notificationId;
            const notificationSnap = await get(
              ref(
                database,
                `notifications/vendors/generalStatus/${userInfo?.userId?.toString()}/${
                  notification?.id
                }`
              )
            );
            const notificationStatus = notificationSnap.val();

            if (notificationStatus?.status === "sent") {
              PlayNotificationSound();
              updateNotificationStatus(
                `notifications/vendors/generalStatus/${userInfo?.userId?.toString()}/${
                  notification?.id
                }/`,
                "unread"
              );
              update(ref(database, `users/${userInfo?.userId}/`), {
                newNotification: true,
              });
            }
            const notificationSnap1 = await get(
              ref(
                database,
                `notifications/vendors/generalStatus/${userInfo?.userId?.toString()}/${
                  notification?.id
                }`
              )
            );
            const notificationStatus1 = notificationSnap1.val();
            if (notificationStatus1?.status === "unread") {
              unread += 1;
            }
          })
      );

      dispatch(
        appManagementActions.updateNotifications({
          unreadVendorGeneralCounts: unread,
        })
      );
      return unread;
    },
    [dispatch, userInfo?.userId]
  );

  // Fetching general notifications
  const calculateUnreadGeneral = useCallback(
    async (notifications) => {
      let unread = 0;
      const notificationIds = Object.keys(notifications || {});

      await Promise.all(
        notificationIds
          .slice(0)
          .reverse()
          .map(async (notificationId) => {
            const notification = { ...notifications[notificationId] };
            notification.id = notificationId;
            const notificationSnap = await get(
              ref(
                database,
                `notifications/generalStatus/${userInfo?.userId?.toString()}/${
                  notification.id
                }`
              )
            );
            const notificationStatus = notificationSnap.val();

            if (notificationStatus?.status === "sent") {
              PlayNotificationSound();
              updateNotificationStatus(
                `notifications/generalStatus/${userInfo?.userId?.toString()}/${
                  notification.id
                }/`,
                "unread"
              );
              update(ref(database, `users/${userInfo?.userId}/`), {
                newNotification: true,
              });
            }
            const notificationSnap1 = await get(
              ref(
                database,
                `notifications/generalStatus/${userInfo?.userId?.toString()}/${
                  notification?.id
                }`
              )
            );
            const notificationStatus1 = notificationSnap1.val();
            if (notificationStatus1?.status === "unread") {
              unread += 1;
            }
          })
      );

      dispatch(
        appManagementActions.updateNotifications({
          unreadGeneralCounts: unread,
        })
      );
      return unread;
    },
    [dispatch, userInfo?.userId]
  );

  useMemo(
    () => calculateUnreadPersonalized(personalized),
    [personalized, calculateUnreadPersonalized]
  );
  useMemo(
    () => calculateUnreadVendorGeneral(vendorGeneral),
    [vendorGeneral, calculateUnreadVendorGeneral]
  );
  useMemo(
    () => calculateUnreadGeneral(general),
    [general, calculateUnreadGeneral]
  );

  /**---------------------------------------Push notification--------------------------------- */
  const onesignalInitiate = async () => {
    await OneSignal.init({ appId: "73adb59b-3fe1-4b3d-b7e6-9850136545a8" });
  };

  useEffect(() => {
    onesignalInitiate();
  }, [userInfo?.userId]);

  return (
    <div className="">
      {loading ? <EjoyshopLoader /> : <RouterProvider router={router} />}
    </div>
  );
}

export default App;
